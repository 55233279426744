import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20221108-Rate-Sheet.pdf";
const date = "08.11.2022";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
        <h1 className="page-title">Changes to our mortgage products</h1>

        <br />
        <p>With effect from Tuesday 8 November 2022 we will be making the following changes to our range:</p>
        <p><strong>Summary of changes</strong></p>
    
        <p><strong>UK Buy To Let Existing Customer Switching</strong></p>
        <ul>
            <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
        </ul>
    
        <p><strong>UK Buy To Let Purchase Range</strong></p>
        <ul>
            <li>5 Year Fixed Standard at 65% and 75% LTV have reduced</li>
            <li>5 Year Fee Saver at 65% and 75% LTV have reduced</li>
        </ul>
    
        <p>We have reintroduced our 2 Year Fixed Standard, 2 Year Tracker Standard and 2 Year Tracker Fee Saver Buy to Let Range for Purchase, Remortgage and Existing Customers Borrowing More.&nbsp;</p>
        <p>We have reintroduced our 2 Year Tracker Standard at 75% LTV and 2 Year Tracker Fee Saver at all LTV&rsquo;s within our Buy to Let Range for Existing Customer Switching.</p>
        <p>There are no other changes to any other rates or to fixed rate end dates.</p>
        <p>An updated mortgage rate sheet will be published on Tuesday 8 November to reflect these changes.</p>
      
  </NewsArticleLayout>
);

export default Article;
